import React, { Component } from 'react'
import { Dropdown } from 'react-bootstrap'
import { connect } from 'react-redux'
import store from '../../redux/store'
import { logoutUser } from '../../redux/actions/userActions'
import {COLORS} from '../util/Theme'

class Navbar extends Component {
  state = {
    profileName: ''
  }
  toggleOffcanvas () {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active')
  }

  logOut = e => {
    e.preventDefault()
    store.dispatch(logoutUser())
    window.location.href = '/#/login'
  }



  goSozlesmeMetinler =()=> {
    window.location.href='/#/sozlesmeMetinler'
  } 

  goHesapAyarlari =()=> {
    window.location.href='/#/hesapAyarlari'
  } 



  componentDidMount () {
    this.setState({ profileName: this.props.user.userCreds.KullaniciAdi })
  }
  render () {
    return (
      <nav className='navbar col-lg-12 col-12 p-lg-0 fixed-top d-flex flex-row'>
        <div className='navbar-menu-wrapper d-flex align-items-center justify-content-between' >
          <button
            className='navbar-toggler navbar-toggler align-self-center'
            type='button'
            onClick={() => document.body.classList.toggle('sidebar-icon-only')}
          >
            <i className='mdi mdi-menu'></i>
          </button>

          <ul className='navbar-nav navbar-nav-right ml-lg-auto'>
            <li className='nav-item  nav-profile border-0 pl-4'></li>
            <li className='nav-item  nav-profile border-0'>
            <div style={{ marginRight: 50, cursor:'pointer'}} onClick={()=> this.goHesapAyarlari()}>Hesap Ayarları</div>
              {/* <Dropdown alignRight>
                <Dropdown.Toggle className='nav-link count-indicator bg-transparent'>
                  <span className='profile-text'>Şifre Değiştir</span>
                </Dropdown.Toggle>
                <Dropdown.Menu className='preview-list navbar-dropdown pb-3'>
                  <Dropdown.Item
                    className='dropdown-item preview-item d-flex align-items-center border-0 mt-2'
                    onClick={evt => evt.preventDefault()}
                  >
                    Hesap Ayarlarım
                  </Dropdown.Item>
                  <Dropdown.Item
                    className='dropdown-item preview-item d-flex align-items-center border-0'
                    onClick={e => this.logOut(e)}
                  >
                    Çıkış Yap
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> */}
            </li>
          </ul>
          <button
            className='navbar-toggler navbar-toggler-right d-lg-none align-self-center'
            type='button'
            onClick={this.toggleOffcanvas}
          >
            <span className='mdi mdi-menu'></span>
          </button>
        </div>
      </nav>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user
})

export default connect(mapStateToProps, {})(Navbar)
