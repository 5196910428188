import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import './App.scss'
import './App.css'
import AppRoutes from './AppRoutes'
import Navbar from './shared/Navbar'
import Sidebar from './shared/Sidebar'
import Footer from './shared/Footer'
import { Provider } from 'react-redux'
import store from '../redux/store'
import { decrypt, anahtar } from '../assets/sifreleme/Sifreleme'
import axios from 'axios'
import jwtDecode from "jwt-decode";
import { logoutUser,getUserInfo } from '../redux/actions/userActions'
import { SET_USER } from '../redux/types.js'


let token = localStorage.IdToken

// if (token) {
//   let token2 = token.split('Bearer ')
//   if (token2) {
//     const decodedToken = decrypt(token2[1], anahtar)
//     const abc = JSON.parse(decodedToken)
//     let date = new Date();
//     let date2 = new Date(abc.Zaman);
//     let diff = Math.abs(new Date(date) - new Date(date2));
//     let minutes = Math.ceil((diff/1000)/60);
//     if (minutes>60) {
//       store.dispatch(logoutUser())
//       window.location.href = '/#/anasayfa'
//     } else {
//        store.dispatch(getUserInfo())
//        axios.defaults.headers.common['Authorization'] = token
//     }
//   }
// }
if (token) {
  const decodedToken = jwtDecode(token);
  if (decodedToken.exp * 1000 < Date.now()) {
    store.dispatch(logoutUser());
    window.location.href = "/#/anasayfa";
  } else {
    store.dispatch({ type: SET_USER });
    axios.defaults.headers.common["Authorization"] = token;
    store.dispatch(getUserInfo());
  }
}



class App extends Component {
  state = {}
  componentDidMount () {
    this.onRouteChanged()
  }
  render () {
    let navbarComponent = !this.state.isFullPageLayout ? <Navbar /> : ''
    let sidebarComponent = !this.state.isFullPageLayout ? <Sidebar /> : ''
    let footerComponent = !this.state.isFullPageLayout ? <Footer /> : ''

    
    return (
      <Provider store={store}>
        <div className='container-scroller'>
          {navbarComponent}
          <div className='container-fluid page-body-wrapper'>
            {sidebarComponent}
            <div className='main-panel'>
              <div className='content-wrapper' style={{padding:0}}>
                <AppRoutes />
              </div>
              {footerComponent}
            </div>
          </div>
        </div>
      </Provider>
    )
  }

  componentDidUpdate (prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged()
    }
  }

  onRouteChanged () {
    console.log('ROUTE CHANGED')
    window.scrollTo(0, 0)
    const fullPageLayoutRoutes = [
      '/user-pages/login-1',
      '/user-pages/login-2',
      '/user-pages/register-1',
      '/user-pages/register-2',
      '/user-pages/lockscreen',
      '/error-pages/error-404',
      '/error-pages/error-500',
      '/general-pages/landing-page',
      '/sozlesmeler',
      '/sozlesmeler2',
      '/login',
      '/api/customer',
      '/api/customer/kaydet',
    ]
    for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
      if (this.props.location.pathname === fullPageLayoutRoutes[i]) {
        this.setState({
          isFullPageLayout: true
        })
        document
          .querySelector('.page-body-wrapper')
          .classList.add('full-page-wrapper')
        break
      } else {
        this.setState({
          isFullPageLayout: false
        })
        document
          .querySelector('.page-body-wrapper')
          .classList.remove('full-page-wrapper')
      }
    }
  }
}

export default withRouter(App)
