import React, { Component, Suspense, lazy } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Spinner from '../app/shared/Spinner'
import Login from '../app/login/Login'
import AuthRoute from '../app/util/AuthRoute'

const Anasayfa = lazy(() => import('./anasayfa/Anasayfa'))
const Ayarlar = lazy(() => import('./ayarlar/Ayarlar'))
const VeriGirisi = lazy(() => import('./veri-girisi/VeriGirisi'))
const Sozlesmeler = lazy(() => import('./anasayfa/Sozlesmeler'))
const Sozlesmeler2 = lazy(() => import('./anasayfa/Sozlesmeler2'))
const VeriListele = lazy(() => import('./veri-listeleme/VeriListeleme'))
const soruSor = lazy(() => import('./soruSor/soruSor'))
const HesapAyarlari = lazy(() => import('./hesapAyarlari/HesapAyarlari'))
const Customer = lazy(() => import('./customer/Customer'))
const CustomerKaydet = lazy(() => import('./customer/CustomerKaydet'))
const SifremiUnuttum = lazy(() => import('./login/SifremiUnuttum'))


class AppRoutes extends Component {
  render () {
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
          <AuthRoute exact path='/hesapayarlari' component={HesapAyarlari} />
          <AuthRoute exact path='/anasayfa' component={Anasayfa} />
          <AuthRoute exact path='/ayarlar' component={Ayarlar} />
          <Route path='/sozlesmeler' component={Sozlesmeler} />
          <Route path='/sozlesmeler2' component={Sozlesmeler2} />
          <AuthRoute path='/veri-girisi' component={VeriGirisi} />
          <AuthRoute path='/veri-listele' component={VeriListele} />
          <AuthRoute path='/soru-sor' component={soruSor} />
          <Route path='/login' component={Login} />
          <Route path='/sifremi-unuttum' component={SifremiUnuttum} />
          <Route exact path='/api/customer/' component={Customer} />
          <Route exact path='/api/customer/kaydet' component={CustomerKaydet} />
          <Redirect to='/anasayfa' />
        </Switch>
      </Suspense>
    )
  }
}

export default AppRoutes
