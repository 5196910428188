export const SET_Departman = 'SET_Departman'
export const SET_Envanter = 'SET_Envanter'
export const SET_Faaliyet = 'SET_Faaliyet'
export const SET_HukukiSebeb = 'SET_HukukiSebeb'
export const SET_IdariTedbir = 'SET_IdariTedbir'
export const SET_IslemeAmaci = 'SET_IslemeAmaci'
export const SET_IslemeYontem = 'SET_IslemeYontem'
export const SET_Kategori = 'SET_Kategori'
export const SET_Musteri = 'SET_Musteri'
export const SET_OzelNitelikli = 'SET_OzelNitelikli'
export const SET_SaklamaOrtam = 'SET_SaklamaOrtam'
export const SET_TeknikTedbir = 'SET_TeknikTedbir'
export const SET_UretilenCikti = 'SET_UretilenCikti'
export const SET_VeriIsleyen = 'SET_VeriIsleyen'
export const SET_VeriKaynak = 'SET_VeriKaynak'
export const SET_Veri = 'SET_Veri'
export const SET_VeriSahibi = 'SET_VeriSahibi'
export const SET_VeriSunan = 'SET_VeriSunan'
export const SET_Musteri_Listesi = 'SET_Musteri_Listesi'
export const SET_SaklamaOrtami = 'SET_SaklamaOrtami'
export const SET_Politika = 'SET_Politika'
export const SET_Mevzuat = 'SET_Mevzuat'
export const SET_Sozlesme = 'SET_Sozlesme'
export const SET_ImhaSebep = '  SET_ImhaSebep'
export const SET_ImhaSure = 'SET_ImhaSure'
export const SET_ImhaYontem = 'SET_ImhaYontem'

export const SET_Envanter_Kaydet = 'SET_Envanter_Kaydet'
export const SET_Envanter_Sil = 'SET_Envanter_Sil'

export const LOADING_DATA = 'LOADING_DATA'
export const SET_ERRORS = 'SET_ERRORS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
export const LOADING_UI = 'LOADING_UI'
export const STOP_LOADING_UI = 'STOP_LOADING_UI'
export const POST_DATA = 'POST_DATA'
export const SET_DATA = 'SET_DATA'
export const SET_Musteri_Kaydet = 'SET_Musteri_Kaydet'
export const SET_Sonuc_Modal = 'SET_Sonuc_Modal'
export const STOP_Sonuc_Modal = 'STOP_Sonuc_Modal'
export const SET_Envanter_Listesi = 'SET_Envanter_Listesi'
export const SET_Ayarlar_Kaydet = 'SET_Ayarlar_Kaydet'
export const SET_Ayarlar_Listesi = 'SET_Ayarlar_Listesi'
export const LOADING_AYARLAR = 'LOADING_AYARLAR'
export const STOP_LOADING_AYARLAR = 'STOP_LOADING_AYARLAR'
export const SET_PersonelSifreDegistir = 'SET_PersonelSifreDegistir'
export const SET_Hazir_Envanter_Listesi = 'SET_Hazir_Envanter_Listesi'
export const SET_SozlesmeListesiAl = 'SET_SozlesmeListesiAl'
export const SET_Musteri_Sorgula = 'SET_Musteri_Sorgula'
export const SET_Firma_Kaydet = 'SET_Firma_Kaydet'
export const SET_Firma_Guncelle = 'SET_Firma_Guncelle'
export const SET_Firma_BilgisiAl = 'SET_Firma_BilgisiAl'

export const SET_USER = 'SET_USER'
export const LOADING_USER = 'LOADING_USER'
export const SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED'
