import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import store from "../../redux/store";
import { logoutUser } from "../../redux/actions/userActions";
import jwtDecode from "jwt-decode";
import Logo from "../../assets/images/BusinessProtection.png";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      bekleyen: [],
      tckn: "",
      ad: "",
      soyad: "",
      tel: "",
      eposta: "",
      userBilgiler: {},
      sonucModal: false,
      error: {
        kimlik: false,
        isim: false,
        soyisim: false,
        ceptel: false,
        email: false,
      },
      KullaniciAdi: "",
      callBackData: "",
    };
  }

  componentDidMount() {
    this.decodeUser();
  }

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
    if (this.state.KullaniciAdi !== prevState.KullaniciAdi) {
      this.decodeUser()
    }
  }

  decodeUser = () => {
    const token = localStorage.getItem("IdToken");
    if (token) {
      const bilgi = jwtDecode(token);
      this.setState({ KullaniciAdi: bilgi.unique_name });
    }
  };

  logOut = (e) => {
    e.preventDefault();
    store.dispatch(logoutUser());
    window.location.href = "/#/login";
  };

  onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");

    const dropdownPaths = [
      { path: "/basic-ui", state: "basicUiMenuOpen" },
      { path: "/form-elements", state: "formElementsMenuOpen" },
      { path: "/tables", state: "tablesMenuOpen" },
      { path: "/icons", state: "iconsMenuOpen" },
      { path: "/charts", state: "chartsMenuOpen" },
      { path: "/user-pages", state: "userPagesMenuOpen" },
    ];

    dropdownPaths.forEach((obj) => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true });
      }
    });
  }

  render() {
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img src={Logo} width={50} style={{ margin: 20 }} />
        </div>

        <ul className="nav">
          <li
            className="nav-item nav-profile not-navigation-link"
            style={{ marginBottom: 20 }}
          >
            <div className="nav-link">
              <div style={{ margin: 5, fontFamily: "Noto Sans" }}>
                Hoşgeldiniz{" "}
              </div>

              <p
                className="profile-name"
                style={{
                  fontSize: 18,
                  fontFamily: "Noto Sans",
                  fontWeight: 900,
                }}
              >
                {this.state.KullaniciAdi}
              </p>
            </div>
          </li>
          <li
            className={
              this.isPathActive("/anasayfa") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/anasayfa">
              <i className="mdi mdi-home menu-icon"></i>
              <span
                className="menu-title"
                style={{ fontSize: 16, fontFamily: "Noto Sans" }}
              >
                Anasayfa
              </span>
            </Link>
          </li>
          <li
            className={
              this.isPathActive("/ayarlar") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/ayarlar">
              <i className="mdi mdi-settings menu-icon"></i>
              <span
                className="menu-title"
                style={{ fontSize: 16, fontFamily: "Noto Sans" }}
              >
                Ayarlar
              </span>
            </Link>
          </li>
          <li
            className={
              this.isPathActive("/veri-girisi") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/veri-girisi">
              <i className="mdi mdi-plus-circle menu-icon"></i>
              <span
                className="menu-title"
                style={{ fontSize: 16, fontFamily: "Noto Sans" }}
              >
                Envanter Girişi
              </span>
            </Link>
          </li>
          <li
            className={
              this.isPathActive("/veri-listele")
                ? "nav-item active"
                : "nav-item"
            }
          >
            <Link className="nav-link" to="/veri-listele">
              <i className="mdi mdi-format-list-bulleted menu-icon"></i>
              <span
                className="menu-title"
                style={{ fontSize: 16, fontFamily: "Noto Sans" }}
              >
                Envanter Listesi
              </span>
            </Link>
          </li>
          <li
            className={
              this.isPathActive("/soru-sor") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/soru-sor">
              <i className="mdi mdi-comment-question-outline menu-icon"></i>
              <span
                className="menu-title"
                style={{ fontSize: 16, fontFamily: "Noto Sans" }}
              >
                Soru Sor
              </span>
            </Link>
          </li>
          <li
            className={"nav-item"}
            style={{
              marginTop: 300,
            }}
          >
            <Link className="nav-link" to="">
              <i className="mdi mdi-logout menu-icon"></i>
              <span
                className="menu-title"
                onClick={(e) => this.logOut(e)}
                style={{ fontSize: 16, fontFamily: "Noto Sans, sans-serif" }}
              >
                Çıkış Yap
              </span>
            </Link>
          </li>
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.decodeUser();
    this.onRouteChanged();
    // add className 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
    this.setState({ KullaniciAdi: this.props.user.userCreds.KullaniciAdi });
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
  user: state.user,
});

export default connect(mapStateToProps, {})(withRouter(Sidebar));
