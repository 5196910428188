import React, { Component } from "react";
import { Button, InputGroup, FormControl, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/Mobile_Payment.png";

//Redux
import { connect } from "react-redux";
import { loginUser } from "../../redux/actions/userActions";
import { COLORS } from "../util/Theme";

import { encrypt, anahtar } from "../../assets/sifreleme/Sifreleme";

class login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      error: "",
      KullaniciAdi: "",
      Sifre: "",
      FirmaKodu: "",
      matches: window.matchMedia("(min-width: 768px)").matches,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  validation = () => {
    let state = this.state;
    let errors = {};
    let formIsValid = true;

    //kullanıcı adı
    if (!state["KullaniciAdi"]) {
      formIsValid = false;
      errors["KullaniciAdi"] = "Boş bırakılamaz";
    }

    //şifre
    if (!state["Sifre"]) {
      formIsValid = false;
      errors["Sifre"] = "Boş bırakılamaz";
    }
    //firma kodu
    if (!state["FirmaKodu"]) {
      formIsValid = false;
      errors["FirmaKodu"] = "Boş bırakılamaz";
    }

    //firma kodu
    if (state.error === 401) {
      formIsValid = false;
      errors["FirmaKodu"] = "Giriş bilgileri hatalı";
    }

    this.setState({ errors: errors });
    return formIsValid;
  };

  componentDidUpdate(prevProps) {
    if (prevProps.user.error !== this.props.user.error) {
      this.setState({ error: this.props.user.error });
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.state.error = "";
    const userData = {
      KullaniciAdi: this.state.KullaniciAdi,
      Sifre: this.state.Sifre,
      FirmaKodu: this.state.FirmaKodu,
    };
    let a = this.validation();
    if (a) {
      this.props.loginUser(userData, this.props.history);
    }
  };

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "100%",
          alignItems: "center",
        }}
      >
        <div
          style={{
            backgroundColor: COLORS.primaryLight,
            display: "flex",
            flexDirection: "column",
            padding: 40,
            alignItems: "center",
            height: "100%",
            width: "50%",
          }}
        >
          {/* <button
            type="submit"
            disabled={this.props.user.loading}
            variant="success"
            style={{
              border: "none",
              backgroundColor: "light-gray",
              height: 40,
              width: 200,
              cursor: "pointer",
              color: "gray",
              borderRadius: 5,
            }}
            onClick={(e) => {
              this.handleSubmit(e);
            }}
          >
            {this.props.user.loading ? (
              <Spinner animation="border" />
            ) : (
              "Yönetici Girişi"
            )}
          </button> */}
          <p
            style={{
              fontFamily: '"Noto Serif", serif',
              fontSize: 50,
              lineHeight: 1.2,
              marginTop: "auto",
            }}
          >
            KVKK Envanter Yönetimi
          </p>
          <p style={{ fontSize: 18, marginTop: 5 }}>
            Kişisel Verileri Koruma Kanunu Yönetim Programı
          </p>
          <div
            style={{
              marginRight: "auto",
              width: "100%",
              color: "red",
              marginBottom: 10,
            }}
          >
            {this.state.error === "1"
              ? " Kullanıcı adı veya şifre yanlış"
              : this.state.error === "2"
              ? "Boşlukları doldurunuz"
              : ""}
          </div>
          <div
            style={{
              marginTop: "auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              marginBottom: 5,
            }}
          >
            <input
              name="KullaniciAdi"
              placeholder="Kullanıcı Adı"
              style={{
                border: "none",
                borderRadius: 5,
                padding: 10,
                margin: 5,
                height: 50,
                width: 300,
                borderColor:
                  this.state.error === "1" ||
                  (this.state.error === "2" && this.state.KullaniciAdi === "")
                    ? "red"
                    : "",
              }}
              onChange={this.handleChange}
              value={this.state.KullaniciAdi}
            />
            <span
              style={{
                color: "red",
                alignSelf: "center",
                marginLeft: 10,
                width: 150,
              }}
            >
              {this.state.errors["KullaniciAdi"]}
            </span>

            <input
              type="password"
              name="Sifre"
              placeholder="Şifre"
              style={{
                border: "none",
                borderRadius: 5,
                padding: 10,
                margin: 5,
                height: 50,
                width: 300,
                borderColor:
                  this.state.error === "1" ||
                  (this.state.error === "2" && this.state.Sifre === "")
                    ? "red"
                    : "",
              }}
              onChange={this.handleChange}
              value={this.state.Sifre}
            />
            <span style={{ color: "red", alignSelf: "center", marginLeft: 10 }}>
              {this.state.errors["Sifre"]}
            </span>

            <input
              name="FirmaKodu"
              placeholder="Firma Kodu"
              style={{
                border: "none",
                borderRadius: 5,
                padding: 10,
                margin: 5,
                height: 50,
                width: 300,
                borderColor:
                  this.state.error === "1" ||
                  (this.state.error === "2" && this.state.FirmaKodu === "")
                    ? "red"
                    : "",
              }}
              onChange={this.handleChange}
              value={this.state.FirmaKodu}
            />
            <span style={{ color: "red", alignSelf: "center", marginLeft: 10 }}>
              {this.state.errors["FirmaKodu"]}
            </span>
          </div>
          <div style={{ color: "red" }}>
            {this.state.error === 401 ? "Giriş Bilgileri Hatalı" : ""}
          </div>
          {/* <div>Şifremi Unuttum</div> */}
          <button
            type="submit"
            disabled={this.props.user.loading}
            variant="success"
            style={{
              border: "none",
              backgroundColor: COLORS.primaryDark,
              height: 50,
              width: 300,
              cursor: "pointer",
              color: "white",
              borderRadius: 5,
            }}
            onClick={(e) => {
              this.handleSubmit(e);
            }}
          >
            {this.props.user.loading ? (
              <Spinner animation="border" />
            ) : (
              "Giriş Yap"
            )}
          </button>

          <br />
          <div>
            {/* <div style={{ marginRight: 'auto', width: '100%' }}>
            Şifremi Unuttum
          </div> */}
          </div>
          <div style={{ marginTop: "auto" }}>
            <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
              Copyright © 2020
              <a
                href="https://www.2cyazilim.net/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: COLORS.secondaryLight }}
              >
                {" "}
                2C Yazılım
              </a>
              . Tüm Hakları Saklıdır.
            </span>
          </div>
        </div>
        <img
          src={Logo}
          style={{
            height: 576,
            alignSelf: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapActionsToProps = {
  loginUser,
};

export default connect(mapStateToProps, mapActionsToProps)(login);
