export const COLORS = {
    text: 'black',
    background: 'white',
    primaryDefault: '#5b7aba',
    primaryDark:'#3c5185',
    primaryLight:'#a5cbef',
    primarySat: '#2060e4',
    secondarySat:'#ff9800',
    secondaryLight:'#ffdfb1',
    tertiary:'#ececec'
  }
export const SIZES = [
    8,
    16,
    24,
    32,
  ]