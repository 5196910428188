import { LOADING_UI, STOP_LOADING_UI, SET_USER, SET_ERRORS ,CLEAR_ERRORS} from '../types'

const initialState = {
  loading: false,
  token: '',
  authenticated: false,
  userCreds: {},
  error: ''
}

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_UI:
      return {
        ...state,
        loading: true
      }
    case STOP_LOADING_UI:
      return {
        ...state,
        loading: false
      }
    case SET_USER:
      return {
        ...state,
        loading: false,
        authenticated: true,
        userCreds: action.payload
      }
    case SET_ERRORS:
      return {
        ...state,
        loading: false,
        authenticated: false,
        error: action.payload
      }
    case CLEAR_ERRORS:
      return {
        ...state,
        error: ''
      }
    default:
      return state
  }
}
