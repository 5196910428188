import { SET_USER, SET_ERRORS, CLEAR_ERRORS, LOADING_UI } from "../types";
import axios from "axios";
import jwtDecode from "jwt-decode";
import Domain from "../../app/util/Domain";

export const loginUser = (userData, history) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  dispatch({ type: CLEAR_ERRORS });
  axios
    .post(Domain + "/api/JWTToken", userData)
    .then((res) => {
      dispatch({ type: SET_ERRORS, payload: res.data });
      setAuthorizationHeader(res.data);
      dispatch(getUserInfo());
      history.push("/#/anasayfa");
    })
    .catch((err) => {
      console.log("login de hata");
      dispatch({
        type: SET_ERRORS,
        payload: err.response.status,
      });
    });
};

export const logoutUser = () => () => {
  localStorage.removeItem("IdToken");
  delete axios.defaults.headers.common["Authorization"];
};

export const getUserInfo = () => (dispatch) => {
  let token = localStorage.IdToken;
  if (token) {
    token = token.split("Bearer ");
    if (token[1]) {
      const decodedToken = jwtDecode(token[1]);
      dispatch({ type: SET_USER, payload: decodedToken });

      return decodedToken;
    }
  }

  return {};
};

const setAuthorizationHeader = (token) => {
  const IdToken = `Bearer ${token}`;
  localStorage.setItem("IdToken", IdToken);
  axios.defaults.headers.common["Access-Control-Allow-Methods"] = "*";
  axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
  axios.defaults.headers.common["Access-Control-Allow-Headers"] =
    "Origin, X-Requested-With, Content-Type, Accept, Authorization, Access-Control-Allow-Origin";
  axios.defaults.headers.common["Authorization"] = IdToken;
};
