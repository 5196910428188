import {
  SET_Kategori,
  SET_Departman,
  SET_Faaliyet,
  SET_Veri,
  SET_IslemeAmaci,
  SET_IslemeYontem,
  SET_HukukiSebeb,
  SET_VeriSahibi,
  SET_VeriSunan,
  SET_VeriIsleyen,
  SET_VeriKaynak,
  SET_UretilenCikti,
  SET_SaklamaOrtam,
  SET_IdariTedbir,
  SET_TeknikTedbir,
  SET_SaklamaOrtami,
  SET_Politika,
  SET_Mevzuat,
  SET_Sozlesme,
  SET_ImhaSebep,
  SET_ImhaSure,
  SET_ImhaYontem,
  LOADING_UI,
  STOP_LOADING_UI,
  SET_Musteri_Listesi,
  SET_Musteri_Kaydet,
  SET_Musteri_Sorgula,
  SET_Sonuc_Modal,
  STOP_Sonuc_Modal,
  SET_Envanter_Kaydet,
  SET_Envanter_Listesi,
  SET_Envanter_Sil,
  SET_Ayarlar_Kaydet,
  SET_Ayarlar_Listesi,
  LOADING_AYARLAR,
  STOP_LOADING_AYARLAR,
  SET_PersonelSifreDegistir,
  SET_Hazir_Envanter_Listesi,
  SET_SozlesmeListesiAl,
  SET_Firma_Kaydet,
  SET_Firma_Guncelle,
  SET_Firma_BilgisiAl
} from "../types";

const initialState = {
  Ayarlar: "",
  Departman: [],
  EnvanterListesi: [],
  EnvanterSonuc: "",
  Faaliyet: [],
  HukukiSebeb: [],
  IdariTedbir: [],
  IslemeAmaci: [],
  IslemeYontem: [],
  Kategori: [],
  Musteri: [],
  OzelNitelikli: [],
  SaklamaOrtam: [],
  TeknikTedbir: [],
  UretilenCikti: [],
  VeriIsleyen: [],
  VeriKaynak: [],
  Veri: [],
  VeriSahibi: [],
  VeriSunan: [],
  KisiselVeri: [],
  loading: false,
  MusteriListesi: [],
  firmaKaydetDonenMesaj: "",
  sonucModal: false,
  Politika: [],
  Mevzuat: [],
  Sozlesme: [],
  ImhaSebebi: [],
  ImhaSuresi: [],
  ImhaYontemi: [],
  AyarlarListesi: [],
  SozlesmeListesi: [],
  loadingAyarlar: false,
  sifreDegistirSonuc: "",
  musteriBilgi: "",
  firmaBilgiler: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_UI:
      return {
        ...state,
        loading: true,
      };
    case LOADING_AYARLAR:
      return {
        ...state,
        loadingAyarlar: true,
      };
    case STOP_LOADING_AYARLAR:
      return {
        ...state,
        loadingAyarlar: false,
      };
    case STOP_LOADING_UI:
      return {
        ...state,
        loading: false,
      };
    case SET_Sonuc_Modal:
      return {
        ...state,
        sonucModal: true,
      };
    case STOP_Sonuc_Modal:
      return {
        ...state,
        sonucModal: false,
      };
    case SET_Musteri_Listesi:
      return {
        ...state,
        MusteriListesi: action.payload,
        loading: false,
      };
    case SET_Musteri_Kaydet:
      return {
        ...state,
        loading: false,
        kaydetDonenMesaj: action.payload,
      };
    case SET_Musteri_Sorgula:
      return {
        ...state,
        loading: false,
        musteriBilgi: action.payload,
      };
    case SET_ImhaSebep:
      return {
        ...state,
        ImhaSebebi: action.payload,
        loading: false,
      };
    case SET_ImhaSure:
      return {
        ...state,
        ImhaSuresi: action.payload,
        loading: false,
      };
    case SET_ImhaYontem:
      return {
        ...state,
        ImhaYontemi: action.payload,
        loading: false,
      };
    case SET_Kategori:
      return {
        ...state,
        Kategori: action.payload,
        loading: false,
      };
    case SET_Departman:
      return {
        ...state,
        Departman: action.payload,
        loading: false,
      };
    case SET_Faaliyet:
      return {
        ...state,
        Faaliyet: action.payload,
        loading: false,
      };
    case SET_Veri:
      return {
        ...state,
        Veri: action.payload,
        loading: false,
      };
    case SET_IslemeAmaci:
      return {
        ...state,
        IslemeAmaci: action.payload,
        loading: false,
      };
    case SET_IslemeYontem:
      return {
        ...state,
        IslemeYontem: action.payload,
        loading: false,
      };
    case SET_VeriSahibi:
      return {
        ...state,
        VeriSahibi: action.payload,
        loading: false,
      };
    case SET_HukukiSebeb:
      return {
        ...state,
        HukukiSebeb: action.payload,
        loading: false,
      };
    case SET_VeriSunan:
      return {
        ...state,
        VeriSunan: action.payload,
        loading: false,
      };
    case SET_VeriIsleyen:
      return {
        ...state,
        VeriIsleyen: action.payload,
        loading: false,
      };
    case SET_VeriKaynak:
      return {
        ...state,
        VeriKaynak: action.payload,
        loading: false,
      };
    case SET_UretilenCikti:
      return {
        ...state,
        UretilenCikti: action.payload,
        loading: false,
      };
    case SET_SaklamaOrtam:
      return {
        ...state,
        SaklamaOrtam: action.payload,
        loading: false,
      };
    case SET_IdariTedbir:
      return {
        ...state,
        IdariTedbir: action.payload,
        loading: false,
      };
    case SET_TeknikTedbir:
      return {
        ...state,
        TeknikTedbir: action.payload,
        loading: false,
      };
    case SET_SaklamaOrtami:
      return {
        ...state,
        SaklamaOrtami: action.payload,
        loading: false,
      };
    case SET_Politika:
      return {
        ...state,
        Politika: action.payload,
        loading: false,
      };
    case SET_Mevzuat:
      return {
        ...state,
        Mevzuat: action.payload,
        loading: false,
      };
    case SET_Sozlesme:
      return {
        ...state,
        Sozlesme: action.payload,
        loading: false,
      };
    case SET_Envanter_Kaydet:
      return {
        ...state,
        EnvanterSonuc: action.payload,
        loading: false,
      };
    case SET_Envanter_Listesi:
      return {
        ...state,
        EnvanterListesi: action.payload,
        loading: false,
      };
    case SET_Ayarlar_Kaydet:
      return {
        ...state,
        Ayarlar: action.payload,
        loading: false,
      };
    case SET_Ayarlar_Listesi:
      return {
        ...state,
        AyarlarListesi: action.payload,
        loading: false,
      };
    case SET_Envanter_Sil:
      return {
        ...state,
        EnvanterSonuc: action.payload,
        loading: false,
      };
    case SET_PersonelSifreDegistir:
      return {
        ...state,
        sifreDegistirSonuc: action.payload,
      };
    case SET_Hazir_Envanter_Listesi:
      return {
        ...state,
        HazirEnvanterListe: action.payload,
        loading: false,
      };
    case SET_SozlesmeListesiAl:
      return {
        ...state,
        SozlesmeListesi: action.payload,
      };
    case SET_Firma_Kaydet:
      return {
        ...state,
        firmaKaydetDonenMesaj: action.payload,
        loading:false
      };
    case SET_Firma_BilgisiAl:
      return {
        ...state,
        firmaBilgiler: action.payload,
        loading: false,
      };
    case SET_Musteri_Sorgula:
      return {
        ...state,
        musteriBilgi: action.payload,
        sonucModal: true,
      };
    default:
      return state;
  }
}
